import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "комопненты-fields"
    }}>{`Комопненты `}<inlineCode parentName="h1">{`<Fields>`}</inlineCode></h1>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<TagField>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<TextField>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<DateField>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<BooleanField>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<ReferenceField>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<ReferenceManyField>`}</inlineCode></li>
    </ul>
    <p>{`Компоненты из группы `}<inlineCode parentName="p">{`Fields`}</inlineCode>{` являются `}<inlineCode parentName="p">{`readonly`}</inlineCode>{` полями, необходимы лишь для отображения информации.
Обычно используются в `}<inlineCode parentName="p">{`List`}</inlineCode>{` и `}<inlineCode parentName="p">{`Show`}</inlineCode>{` компонентах.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`source (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`record (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`reference (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "пример-использования-в-show"
    }}>{`Пример использования в `}<inlineCode parentName="h2">{`<Show>`}</inlineCode></h2>
    <pre><code parentName="pre" {...{}}>{`<Show>
    <FieldList>
        <TextField source="id" label="ID" />
        <TextField source="title" label="Title" />
        <TextField source="body" label="Body" />
    </FieldList>
</Show>

`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`\`FieldList\` - компонент для формирования списка из \`Field\` компонентов.
В качестве потомков может принимать не только \`Field\`, но и вложенные \`FieldList\`, а также другие компоненты отображения.
`}</code></pre>
    <h2 {...{
      "id": "пример-использования-в-list"
    }}>{`Пример использования в `}<inlineCode parentName="h2">{`<List>`}</inlineCode></h2>
    <pre><code parentName="pre" {...{}}>{`const columns: ColumnT[] = [
    {
      title: 'ID',
      source: '_id',
      Field: TextField,
    },
    {
      title: 'Created At',
      source: 'createdAt',
      Field: DateField,
    },
];

<List columns={columns} />;
`}</code></pre>
    <h2 {...{
      "id": "создание-своего-field-компонента"
    }}>{`Создание своего `}<inlineCode parentName="h2">{`<Field>`}</inlineCode>{` компонента`}</h2>
    <p>{`Компонент `}<inlineCode parentName="p">{`<Field>`}</inlineCode>{` принимает два обязательных `}<inlineCode parentName="p">{`props`}</inlineCode>{` - `}<strong parentName="p"><inlineCode parentName="strong">{`record`}</inlineCode></strong>{` (объкт данных, из которого будут выбраны данные), `}<strong parentName="p"><inlineCode parentName="strong">{`source`}</inlineCode></strong>{` - ключ, по которому нужно достать значение и `}<strong parentName="p"><inlineCode parentName="strong">{`children`}</inlineCode></strong>{` функцию с аргументов `}<strong parentName="p"><inlineCode parentName="strong">{`value`}</inlineCode></strong>{` (значение из объкта `}<inlineCode parentName="p">{`record`}</inlineCode>{`, по ключу `}<inlineCode parentName="p">{`source`}</inlineCode>{`)`}</p>
    <pre><code parentName="pre" {...{}}>{`<Field record={record} source={source}>
    {(value) => <p>{value}</p>}
</Field>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      